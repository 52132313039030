
export default {
 props: {
  hasChanges: {
   type: Boolean,
   default: false,
  },
  state: {
   type: String,
   required: true,
  },
 },

 data() {
  return {
   isChanged: this.hasChanges,
  };
 },

 computed: {
  editMode: {
   get() {
    return this.state;
   },
   set(val) {
    this.$emit("enableEdit", val);
   },
  },
 },

 watch: {
  hasChanges: function(pageChanged) {
   this.isChanged = pageChanged;
  },
 },

 methods: {
  switchState: function(state) {
   this.editMode = state;
  },
  saveChanges: function() {
   this.$emit("save");
   this.switchState("saved");
   //temp state to tell the user the changes has been saved. could be improved. :)
   setTimeout(() => {
    this.switchState("read");
    this.editMode = "read";
   }, 5000);
  },
 },
};
