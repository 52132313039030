
export default {
 props: {
  pageId: {
   type: String,
   required: true,
  },

  pageMarked: {
   type: Boolean,
   required: true,
  },

  edit: {
   type: Boolean,
   required: true,
   default: false,
  },
 },

 data() {
  return {
   editMode: this.edit,
  };
 },

 watch: {
  edit: function(value) {
   this.editMode = value;
  },
 },

 methods: {
  clickNext() {
   this.$emit("navigate-next");
  },

  clickMark() {
   this.$emit("mark-page", this.pageId);
  },

  clickPrevious() {
   this.$emit("navigate-previous");
  },
 },
};
