
export default {
 props: {
  id: {
   type: String,
   required: true,
  },

  label: {
   type: String,
   required: true,
  },

  selected: {
   type: Boolean,
   default: false,
  },

  checked: {
   type: Boolean,
   default: false,
  },
 },

 data() {
  return {
   checkedInternal: this.checked,
  };
 },

 watch: {
  checked(value) {
   this.checkedInternal = value;
  },

  checkedInternal(value) {
   this.$emit("change", { id: this.id, state: value });
  },

  selected() {
   this.$refs.container.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
   });
  },
 },

 methods: {
  click() {
   this.$emit("navigate", this.id);
  },
 },
};
