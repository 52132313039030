// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Ycwcs{align-items:center;background-color:var(--color-white);cursor:pointer;display:flex;flex-shrink:0;height:2.5rem;justify-content:space-between;padding-left:2.25rem;padding-right:1rem;position:relative;-webkit-text-decoration:underline;text-decoration:underline;transition-duration:var(--transition-duration-shortest);transition-property:background-color;transition-timing-function:ease-in-out}.container_Ycwcs.selected_vpgG0,.container_Ycwcs:hover{-webkit-text-decoration:none;text-decoration:none}.container_Ycwcs.checked_j5H9G{background-color:var(--color-green-50);border-color:var(--color-green-900)!important}.icon_sw\\+rY{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:opacity;transition-timing-function:ease-in-out}.container_Ycwcs.selected_vpgG0 .icon_sw\\+rY{opacity:1}.container_Ycwcs:not(.selected_vpgG0):not(:hover) .icon_sw\\+rY{opacity:0}.container_Ycwcs:not(:last-child){border-bottom:1px dotted var(--color-black-100)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Ycwcs",
	"selected": "selected_vpgG0",
	"checked": "checked_j5H9G",
	"icon": "icon_sw+rY"
};
module.exports = ___CSS_LOADER_EXPORT___;
