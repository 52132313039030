
export default {
  props: {
    markings: {
      type: Array,
      required: true
    },
    downloadCount: {
      type: Number,
      required: true
    },
    hasEdits: {
      type: Boolean,
      required: true
    }
  },
  data(){
    return {
      activeMarkings: [],
      downloads: this.downloadCount,
    }
  },
  watch: {
    downloadCount: function(count) {
      this.downloads = count;
    },

    markings: function (updated) {
      //group pages for each act in a map.
      let markMap = new Map();
      updated.forEach(marking => {
        markMap.has(marking.dlrDocumentId) ?
        markMap.get(marking.dlrDocumentId).push(marking.page) :
        markMap.set(marking.dlrDocumentId, [marking.page])
      });

      //reset active markings
      this.activeMarkings = [];

      //create and prettify view of akt xx - side xx with translations.
      for (const [key, value] of markMap.entries()) {
        const sortedPages = value.sort((a, b) => a - b).join(",");
        const act = this.$t("ESTAID_EASEMENT_ACT_TITLE", {name: key});
        const pages = this.$t("ESTAID_EASEMENT_ACT_PAGE", {number: sortedPages}).toLowerCase();
        const view = `${act} - ${pages}`;
        this.activeMarkings.push(view);
      }
    }
  },
  methods: {
    download() {
      this.$emit("download");
    }
  }
};
