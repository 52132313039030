// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_\\+I7KH{background-color:var(--color-black-50);cursor:pointer;display:flex;flex-direction:column;transition-duration:var(--transition-duration-shortest);transition-property:background-color;transition-timing-function:ease-in-out;-webkit-user-select:none;-moz-user-select:none;user-select:none}.container_\\+I7KH:not(.selected_3n\\+h3){background-color:#fff}.container_\\+I7KH:not(:last-child){border-bottom:1px solid var(--color-black-50)}.container_\\+I7KH.selected_3n\\+h3{border-bottom-color:transparent}.label_KxFzZ,.slot_Rzbir{display:flex}.label_KxFzZ{justify-content:space-between;padding:.5rem .75rem}.markingsCount_1IQu5{align-items:center;background-color:var(--color-green-900);border-radius:100%;display:flex;height:1.5rem;justify-content:center;width:1.5rem}.slot_Rzbir{flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_+I7KH",
	"selected": "selected_3n+h3",
	"label": "label_KxFzZ",
	"slot": "slot_Rzbir",
	"markingsCount": "markingsCount_1IQu5"
};
module.exports = ___CSS_LOADER_EXPORT___;
