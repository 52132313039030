
export default {
  props: {
    id: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    markingsCount: {
      type: Number,
      required: true
    },

    open: {
      type: Boolean,
      default: false
    },

    selected: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    click() {
      this.$emit("click", this.id);
    }
  }
};
