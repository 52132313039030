
export default {
  props: {
    textLabel: {
      type: String,
      required: true,
      default: ""
    },

    textBody: {
      type: String,
      required: true,
      default: ""
    }
  }
};
