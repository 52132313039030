// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_MfzXK{align-items:center;border-bottom:1px solid var(--color-black-50);display:flex;flex-shrink:0;height:3.75rem;justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_MfzXK"
};
module.exports = ___CSS_LOADER_EXPORT___;
