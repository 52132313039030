
import Layout from "./Layout.vue";
import PageGroup from "./PageGroup.vue";
import Page from "./Page.vue";
import Navigation from "./Navigation.vue";
import Description from "./Description.vue";
import Markings from "./Markings.vue";
import Download from "./Download.vue";
import Edit from "./Edit.vue";
import WebViewer from "~/components/webviewer/WebViewer";
import DownloadAct from "@/graphql/Download/DownloadAct.gql";
import { createEasementDateId } from "~/helpers/property-helpers";
import { uniqueElementsBy } from "@/helpers/sort-helpers";
import { PDFDocument } from "pdf-lib";
import estaidEasementForCurrentUser from "~/graphql/Property/EstaidEasement.gql";
import createEasement from "~/graphql/Property/CreateEstaidEasement.gql";
import createEasementDownload from "@/graphql/Download/DownloadEstaidEasement.gql";
import download from "downloadjs";
import { getInstance } from "@pdftron/pdfjs-express-viewer";

export default {
  layout: "default",
  name: "EstaidEasementDialog",

  components: {
    Layout,
    PageGroup,
    Page,
    Navigation,
    Description,
    Markings,
    Download,
    WebViewer,
    Edit,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeEasement: {
      type: Object,
      required: true,
    },
    allEasements: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      chosenEasement: {},
      pdfContent: {},
      availableEasements: this.allEasements,
      easementsWithActs: [],
      navigationEasements: [],
      activeActPageCount: [],
      activeActFileName: "",
      markingsInEasement: [],
      chosenPageNo: 1,
      isPageMarked: false,
      isEdit: false,
      pageCountIsOpen: false,
      editMode: "write",
      currentDownloads: 0,
      showIntro: this.$cookies.get("easements-finder-show-intro") != "false",
    };
  },

  computed: {
    loading: function () {
      return false;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    activeEasement: function (updatedEasement) {
      if (!updatedEasement) {
        return;
      }
      const estaidEasement = updatedEasement.estaidEasement;
      this.markingsInEasement = estaidEasement !== null && estaidEasement.markings !== null ? [...estaidEasement.markings] : [];

      this.currentDownloads = estaidEasement !== null ? estaidEasement.verifications : 0;
      this.chosenEasement = updatedEasement;
      this.chosenEasement.estaidEasement = estaidEasement;
      let actName = this.chosenEasement.filename;
      if (estaidEasement !== null && estaidEasement.markings !== null) {
        const firstMarkingWithPresentActName = estaidEasement.markings.sort().find((m) => m.dlrDocumentId !== null);
        actName = firstMarkingWithPresentActName.dlrDocumentId;
      }

      if (actName === "") {
        const firstEasementWithFilename = this.easementsWithActs.find((e) => e.filename !== "") || {};
        actName = firstEasementWithFilename.filename;
      }

      this.fetchAct({ filename: actName });
    },

    allEasements: function (all) {
      this.getEasementsWithActsAndSetNavigation(all);
    },
  },
  mounted() {
    this.getEasementsWithActsAndSetNavigation(null);
  },
  methods: {
    openIntro() {
      this.$amplitude.helpButton();
      this.showIntro = true;
    },

    hideIntro() {
      this.$cookies.set("easements-finder-show-intro", false, 60 * 60 * 24 * 30 * 12);

      this.showIntro = false;
    },

    getFirstPageInAct(actFileName) {
      const estaidEasement = this.chosenEasement.estaidEasement;
      const firstPage = 1;
      if (estaidEasement === null || estaidEasement.markings === null) {
        return firstPage;
      }

      const hasMarkingInAct = estaidEasement.markings.find((marking) => marking.dlrDocumentId === actFileName);
      if (!hasMarkingInAct) {
        return firstPage;
      }

      const firstMarkingWithPresentActName = this.getMarkingByActFilename(estaidEasement.markings, actFileName);
      return firstMarkingWithPresentActName.page;
    },
    getMarkingByActFilename(markings, actFileName) {
      return markings
        .sort(function (a, b) {
          return a.page - b.page;
        })
        .find((m) => m.dlrDocumentId !== null && m.dlrDocumentId === actFileName);
    },
    getDocumentDateId(easement) {
      return easement.documentIdentification ? createEasementDateId(easement) : "";
    },
    getDocumentTitle(easement) {
      return easement.documentIdentification ? `${this.$t("EASEMENT_DEFAULT_NAME")} ${easement.priority}` : "";
    },
    getTitle(easement) {
      //the headline could be used in the "Description" component header instead of documentDateId.
      if (easement.texts && easement.texts.length > 0) {
        return easement.texts[0].headline + ": " + easement.texts[0].section;
      } else {
        return easement.textSummary;
      }
    },
    jumpToPage(page) {
      this.chosenPageNo = page;
    },
    async fetchAct(act) {
      this.activeActFileName = act.filename;
      const resp = await this.$apollo.query({
        query: DownloadAct,
        variables: {
          id: act.filename,
        },
      });

      if (resp.data.downloadAct !== undefined) {
        this.pdfContent = {
          url: `https://${process.env.fileGateway}/downloads/${resp.data.downloadAct}`,
          page: this.getFirstPageInAct(act.filename),
        };
        //to get page count.
        const existingPdfBytes = await fetch(`https://${process.env.fileGateway}/downloads/${resp.data.downloadAct}`).then((res) => res.arrayBuffer());
        const activeAct = await PDFDocument.load(existingPdfBytes);
        this.activeActPageCount = Array.from({ length: activeAct.getPageCount() }, (v, k) => k + 1);
        this.jumpToPage(1);
      }
    },

    getPagesInMarkings(actFileName) {
      const activeEasement = this.chosenEasement.estaidEasement;
      if (activeEasement === null || activeEasement === undefined || activeEasement.markings === null) {
        return null;
      }

      return activeEasement.markings.filter((marking) => marking.dlrDocumentId === actFileName).map((marking) => marking.page);
    },
    notificationClose() {
      // Reset state
      this.chosenPageNo = 1;
      this.show = false;
      this.$amplitude.closeServitutfinder();
    },

    clickPageGroup(easementAct) {
      if (this.activeActFileName === easementAct.filename) {
        //reset to close PageGroup.
        //  this.activeActFileName = "";
        this.pageCountIsOpen = !this.pageCountIsOpen;
        return;
      }
      this.pageCountIsOpen = true;

      this.fetchAct(easementAct);
      this.$amplitude.changeAct();
    },

    navigatePage(pageNo) {
      this.chosenPageNo = Number.parseInt(pageNo);
      this.$refs.webviewer?.setPage(this.chosenPageNo);
    },
    navigateDocumentNext(element) {
      const navigation = this.navigationEasements;
      const currentIndex = navigation.findIndex((e) => e.documentIdentification === element.documentIdentification);
      let index = currentIndex + 1;
      let navigateToEasement = navigation[index === navigation.length ? 0 : index];
      this.fetchEstaidEasementAndNavigate(navigateToEasement);
      this.$amplitude.nextEasement();
    },
    navigateDocumentPrevious(element) {
      const navigation = this.navigationEasements;
      const currentIndex = navigation.findIndex((e) => e.documentIdentification === element.documentIdentification);
      let index = currentIndex - 1;
      let navigateToEasement = navigation[currentIndex === 0 ? navigation.length - 1 : index];
      this.fetchEstaidEasementAndNavigate(navigateToEasement);
      this.$amplitude.previousEasement();
    },
    getMarkings(easementAct) {
      return this.markingsInEasement.filter((marking) => marking.dlrDocumentId === easementAct.filename).length;
    },
    fetchEstaidEasementAndNavigate(navigateToEasement) {
      this.$apollo
        .query({
          query: estaidEasementForCurrentUser,
          variables: {
            documentIdentification: navigateToEasement.documentIdentification,
          },
          fetchPolicy: "no-cache",
        })
        .then((resp) => {
          this.markingsInEasement = resp.data.estaidEasementForCurrentUser !== null ? resp.data.estaidEasementForCurrentUser.markings : [];
          this.currentDownloads = resp.data.estaidEasementForCurrentUser !== null ? resp.data.estaidEasementForCurrentUser.downloads : 0;

          const clone = structuredClone(navigateToEasement);

          clone.estaidEasement = resp.data.estaidEasementForCurrentUser;
          this.chosenEasement = clone;

          let actName = clone.filename;
          if (clone.estaidEasement !== null && clone.estaidEasement.markings !== null) {
            const firstMarkingWithPresentActName = clone.estaidEasement.markings.sort().find((m) => m.dlrDocumentId !== null);
            actName = firstMarkingWithPresentActName.dlrDocumentId;
          }

          if (actName === "") {
            const firstEasementWithFilename = this.easementsWithActs.find((e) => e.filename !== "");
            actName = firstEasementWithFilename.filename;
          }

          this.fetchAct({ filename: actName });
        });
    },

    async markPage(no) {
      const pageNo = parseInt(no, 10);
      if (!this.validateActIsPresent()) {
        return;
      }

      const isPageMarked = this.isPageIncluded(pageNo);

      if (!isPageMarked) {
        const { Core } = getInstance();
        const document = Core.documentViewer.getDocument();
        const pageText = await document.loadPageText(pageNo);
        const currentActFilename = this.activeActFileName;

        const marking = {
          page: pageNo,
          text: pageText,
          dlrDocumentId: currentActFilename,
          sortIndex: pageNo,
        };

        this.markingsInEasement = [...this.markingsInEasement, marking];
      } else {
        const marking = this.markingsInEasement.find((mark) => mark.page === pageNo);
        const index = this.markingsInEasement.indexOf(marking);
        this.markingsInEasement.splice(index, 1);
      }

      this.isEdit = true;
    },

    async saveEstaidEasement() {
      //removal of typename...
      const markings = this.markingsInEasement.map((marking) => {
        return {
          page: marking.page,
          text: marking.text,
          dlrDocumentId: marking.dlrDocumentId,
          sortIndex: marking.sortIndex == null ? marking.page : 0,
        };
      });
      const resp = await this.$apollo.mutate({
        mutation: createEasement,
        variables: {
          easementInput: {
            documentIdentification: this.chosenEasement.documentIdentification,
            status: "OK",
            markings: markings,
          },
        },
      });

      this.chosenEasement.estaidEasement = resp.data.createEasement;
    },
    navigatePageNext() {
      if (!this.validateActIsPresent()) {
        return;
      }
      const currentPage = this.chosenPageNo;
      if (currentPage === this.activeActPageCount.length) {
        return;
      }
      this.$refs.webviewer?.setPage(currentPage + 1);
    },
    navigatePagePrevious() {
      if (!this.validateActIsPresent()) {
        return;
      }
      const currentPage = this.chosenPageNo;
      if (currentPage === 1) {
        return;
      }
      this.$refs.webviewer?.setPage(currentPage - 1);
    },
    validateActIsPresent() {
      //no act chosen = can't mark or navigate.
      return this.activeActFileName !== "";
    },
    getEasementsWithActsAndSetNavigation(updated) {
      const listToUse = updated !== null ? updated : this.availableEasements;

      this.navigationEasements = listToUse?.flatMap((parent) => parent.easements) ?? [];

      const easementsWithFilenames = listToUse.flatMap((parent) => parent.easements).filter((ease) => ease.filename !== "");

      this.easementsWithActs = uniqueElementsBy(easementsWithFilenames, (x, y) => x.filename === y.filename);
    },
    isPageIncluded(pageNo) {
      return this.markingsInEasement?.filter((marking) => marking.page === pageNo && marking.dlrDocumentId === this.activeActFileName).length > 0;
    },
    async downloadEstaidEasement() {
      // First save in case any changes been made
      if (this.isEdit) {
        await this.saveEstaidEasement();
      }
      //first time this is done, the BE(datamodel) supports adding a EstaidFileId,
      //so it can be retrieved from there in the future, instead of created AD-HOC every time.
      let actEasement = new Map();
      this.markingsInEasement.forEach((marking) => {
        actEasement.has(marking.dlrDocumentId)
          ? actEasement.get(marking.dlrDocumentId).push(marking.page)
          : actEasement.set(marking.dlrDocumentId, [marking.page]);
      });

      const pdfDoc = await PDFDocument.create();
      for (const [key, value] of actEasement.entries()) {
        const resp = await this.$apollo.query({
          query: DownloadAct,
          variables: {
            id: key,
          },
        });

        if (resp.data.downloadEasement !== undefined || resp.data.downloadAct !== undefined) {
          const downloadedUrl = `https://${process.env.fileGateway}/downloads/${resp.data.downloadAct}`;
          const existingPdfBytes = await fetch(downloadedUrl).then((res) => res.arrayBuffer());
          const pdfAct = await PDFDocument.load(existingPdfBytes);
          // Copy the chosen pages from the easement into the new pdf doc.
          // Selected pages are 1-based, so we need to subtract 1 from the page number.
          const sortedPages = value.map((n) => n - 1).sort((a, b) => a - b);
          const copiedPages = await pdfDoc.copyPages(pdfAct, sortedPages);

          copiedPages.forEach((page) => pdfDoc.addPage(page));
        }
      }

      // Serialize the PDFDocument to bytes
      const pdfBytes = await pdfDoc.save();

      // Trigger the browser to download the PDF document.
      const generatedFileId = this.chosenEasement.documentIdentification;
      await download(pdfBytes, `${generatedFileId}.pdf`, "application/pdf");
      await this.incrementDownloaded();

      this.$amplitude.downloadAndVerifyEasement({
        actID: this.markingsInEasement
          .map((marking) => marking.dlrDocumentId)
          .filter((v, i, a) => a.indexOf(v) === i)
          .join(","),
        easementID: this.chosenEasement.documentIdentification,
        pagesMarked: this.markingsInEasement.map((marking) => marking.page).join(","),
      });
    },
    async incrementDownloaded() {
      const easementId = this.chosenEasement.estaidEasement.id;
      await this.$apollo
        .mutate({
          mutation: createEasementDownload,
          variables: {
            easementId: easementId,
          },
        })
        .then((resp) => {
          this.currentDownloads = resp.data.createEasementDownload.verifications;
        });

      this.isEdit = false;
    },
    getAkt() {
      const akt = this.activeActFileName;
      if (akt === "") {
        return;
      }

      const chosenText = this.$t("ESTAID_EASEMENT_PROPERTY_ACTS_CHOSEN");
      return "- " + chosenText + ": " + akt;
    },
    cancelEdits() {
      const oldMarks =
        this.activeEasement?.estaidEasement !== null && this.activeEasement.estaidEasement.markings !== null
          ? [...this.activeEasement.estaidEasement.markings]
          : [];

      this.markingsInEasement = oldMarks;
      this.isEdit = false;
    },
  },
};
