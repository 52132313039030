
export default {
  props: {
    buttonNextVisible: {
      type: Boolean,
      required: true
    },

    buttonPreviousVisible: {
      type: Boolean,
      required: true
    },

    textLabel: {
      type: String,
      required: true,
      default: ""
    }
  },

  methods: {
    clickNext() {
      this.$emit("click-next");
    },

    clickPrevious() {
      this.$emit("click-previous");
    }
  }
};
