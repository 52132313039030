// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_BSSQq{align-items:center;background-color:var(--color-black-50);display:flex;justify-content:center;padding:1rem .5rem;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_BSSQq"
};
module.exports = ___CSS_LOADER_EXPORT___;
